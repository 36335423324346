import React from "react"
import { Bar } from "react-chartjs-2"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js"
import { Box } from "@mui/material"

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

const BarGraphB = () => {
  const barGraphData = {
    labels: [
      "Local Business Support",
      "Education",
      "Infrastructure",
      "Government Services",
    ],
    datasets: [
      {
        label: "General Fund Allocation By $",
        data: [1568000, 1344000, 1344000, 224000], // Corresponding values
        backgroundColor: [
          "rgba(221, 0, 50, 0.6)", // local businesses
          "rgba(11, 81, 255, 0.6)", //education
          "rgba(153, 102, 255, 0.6)", // infrastructure
          "rgba(3, 198, 6, 0.6)", //govn't
        ],
      },
    ],
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: "General Fund Allocation by $",
        font: {
          size: 22, // Adjust the title size
        },
      },
      subtitle: {
        // SubTitle is correctly named and registered
        display: true,
        text: "Based off an average of $35,000,000 in annual revenue",
        font: {
          size: 10,
          style: "italic",
        },
        padding: {
          bottom: 20,
        },
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            return "$" + tooltipItem.raw.toLocaleString() // Add $ symbol and format the number
          },
        },
      },
    },
    scales: {
      y: {
        ticks: {
          // stepSize: 500000, // Define the step size for the Y-axis (500,000 increments)
          // maxTicksLimit: 5, // Ensure 5 ticks are shown
          callback: function (value) {
            return "$" + value.toLocaleString() // Add $ symbol and format numbers
          },
          font: {
            size: 14, // Increase font size for Y-axis
          },
        },
      },
      x: {
        ticks: {
          font: {
            size: 12, // Increase font size for X-axis
          },
          minRotation: 20, // Ensure text is straight
          maxRotation: 20, // Prevent slanting
        },
      },
    },
  }

  return (
    <Box
      sx={{
        width: { xs: "100%", sm: "600px" },
        height: "400px",
      }}
    >
      <Bar data={barGraphData} options={options} />
    </Box>
  )
}

export default BarGraphB
