import React from "react"
import { Pie } from "react-chartjs-2"
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js"
import { Box } from "@mui/material"
import { containerStyle } from "./chartStyles"

// Register necessary components for the Pie chart
ChartJS.register(ArcElement, Tooltip, Legend)

const PieChartBb = () => {
  // Data for the pie chart
  const data = {
    labels: [
      "Govn't Services",
      "Infrastructure",
      "Education",
      "Local Business Support",
    ],
    datasets: [
      {
        label: "General Fund Allocation",
        data: [5, 30, 30, 35], // Percentage breakdown
        backgroundColor: [
          "rgba(3, 198, 6, 0.6)", //govn't
          "rgba(153, 102, 255, 0.6)", // infrastructure
          "rgba(11, 81, 255, 0.6)", //education
          "rgba(221, 0, 50, 0.6)", // local businesses
        ],
        borderColor: [
          "rgba(3, 198, 6, 0.6)",
          "rgba(153, 102, 255, 1)",
          "rgba(11, 81, 255, 0.6)",
          "rgba(221, 0, 50, 0.6)",
        ],
        borderWidth: 1,
      },
    ],
  }

  // Options for the pie chart
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "General Fund Allocation by %",
        font: {
          size: 22, // Adjust the title size
        },
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const value = context.raw // Access the data value
            return value + "%" // Append '%' to the value
          },
        },
      },
    },
  }
  // Styling to shrink the chart to 50% on desktop

  return (
    <Box sx={containerStyle}>
      <Pie data={data} options={options} />{" "}
    </Box>
  )
}

export default PieChartBb
