import {
  Box,
  Button,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import React from "react"
// import FacebookIcon from "@mui/icons-material/Facebook"
import CircleIcon from "@mui/icons-material/Circle"
import actionItems from "../actionItems.json"
import FindInPageIcon from "@mui/icons-material/FindInPage"

const Links = () => {
  const theme = useTheme()
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"))
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        alignItems: "center",
        padding: "2rem",
        gap: "2rem",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "1rem",
        }}
      >
        {/* <Typography textAlign={"center"}>
          Join our community on Facebook!
        </Typography>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.facebook.com/groups/highlandparkhighwoodcommunity"
          style={{
            textDecoration: "none",
            color: "black",
          }}
        >
          <Button sx={{ display: "flex", gap: "1rem" }}>
            <Typography>Click here to join</Typography>{" "}
            <FacebookIcon sx={{ transform: "scale(2)" }} />
          </Button>
        </a> */}
        <Typography fontWeight={600}>
          Since this page was created, Hoobler has since been officially
          stripped of his council seat. The Brewery and the Festival have also
          reached a "mutual agreement." I still encourage you to
        </Typography>
        <a
          href="./assets/FOIA_Response_Harris_240401.pdf"
          download="FOIA_Response_Harris_240401"
          target="_blank"
        >
          <Button
            endIcon={<FindInPageIcon />}
            variant="contained"
            color="success"
          >
            <Typography fontWeight={600}>Download FOIA #1</Typography>
          </Button>
        </a>
        <a
          href="./assets/FOIA_Response_Kane_240418.pdf"
          download="FOIA_Response_Kane_240418"
          target="_blank"
        >
          <Button
            endIcon={<FindInPageIcon />}
            variant="contained"
            color="success"
          >
            <Typography fontWeight={600}>Download FOIA #2</Typography>
          </Button>
        </a>
        <a
          href="./assets/Kane_Responsive_EMails.pdf"
          download="Kane_Responsive_EMails"
          target="_blank"
        >
          <Button
            endIcon={<FindInPageIcon />}
            variant="contained"
            color="success"
          >
            <Typography fontWeight={600}>Download FOIA #3</Typography>
          </Button>
        </a>
        <a
          href="./assets/FOIA Response - Kane 240501.pdf"
          download="FOIA Response - Kane 240501"
          target="_blank"
        >
          <Button
            endIcon={<FindInPageIcon />}
            variant="contained"
            color="success"
          >
            <Typography fontWeight={600}>Download FOIA #4</Typography>
          </Button>
        </a>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: ".75rem",
            borderBottom: "1px solid black",
            padding: "1rem 0",
          }}
        >
          {actionItems.map((item, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
                gap: "1rem",
              }}
            >
              <CircleIcon />
              <Typography>
                {item.content}{" "}
                {item.link && (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    download={item.download}
                    href={`${item.link}`}
                  >
                    {item.linkName}
                  </a>
                )}
              </Typography>
            </Box>
          ))}
        </Box>
        <Grid
          container
          sx={{
            justifyContent: "center",
            alignItems: "center",
            width: { md: "75%" },
          }}
        >
          <Grid item md={5}>
            <Typography>
              Here is a video from the recent Highland Park City Hall meeting
              (2/12/2024), marking the onset of much of the controversy.
              Notably, an entire minute featuring local resident Levi Kane was
              omitted in the official release. Kane has responded by sharing the
              complete, unedited version of his three-minute statement, also
              available for viewing below.
            </Typography>
          </Grid>
          <Grid item md={7}>
            <iframe
              width={isMobileScreen ? "375" : "560"}
              height={isMobileScreen ? "280" : "315"}
              src="https://www.youtube.com/embed/gd84Ak0U0IA?si=XGTDt-XoKGqLXPua"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowFullScreen
            />

            <iframe
              width={isMobileScreen ? "375" : "560"}
              height={isMobileScreen ? "280" : "315"}
              src="https://www.youtube.com/embed/22TbNc6Qf80?si=13QTyPe97JAglqIO"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowFullScreen
            />
          </Grid>
          <Grid item md={5}>
            <Typography>
              An additional link from April 10, 2024, reveals a significantly
              smaller yet unanimously supportive crowd advocating for the
              amendment of the law. One key provision approved during this
              meeting grants every business the right to obtain a liquor permit.
              However, this comes with a significant stipulation: business
              owners will be precluded from running for or holding any city
              office. Despite the potential implications of such a legislative
              change, the assembly opted to pass the measure without dedicating
              sufficient time—two weeks—to fully evaluate the potential
              advantages and disadvantages
            </Typography>
          </Grid>
          <Grid item md={7}>
            <iframe
              width={isMobileScreen ? "375" : "560"}
              height={isMobileScreen ? "280" : "315"}
              src="https://www.youtube.com/embed/5lRzJ4vcdnE?si=E8OXRktM-34niDjE"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowFullScreen
            />
          </Grid>
          <Grid item md={5}>
            <Typography>
              The video featured below captures a poignant moment from April
              24th, 2024, where approximately 200-250 residents of Highland Park
              convened at the city hall. They united in a heartfelt appeal for
              the council to convene a special session aimed at amending the
              liquor law before the impending resignation of Jeff Hoobler on
              April 30th. This edited version of the council meeting has been
              carefully curated to highlight only those segments that pertain to
              contributions from the public, emphasizing the community's active
              engagement in civic matters.
            </Typography>
          </Grid>
          <Grid item md={7}>
            <iframe
              width={isMobileScreen ? "375" : "560"}
              height={isMobileScreen ? "280" : "315"}
              src="https://www.youtube.com/embed/ztrEOztFsvc?si=hFlmiwFB9JfBjj9t"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            />
          </Grid>
          <Grid item md={5}>
            <Typography>
              Kane sets the record straight. With a comprehensive total of 1,491
              pages of FOIA documents spanning from January 1 to April 30, the
              day of Jeff's resignation, the latter half displayed in this video
              reveals merely two sources of opposition. The Mayor and her two
              associates have undeniably exposed themselves as corrupt.
            </Typography>
          </Grid>
          <Grid item md={7}>
            <iframe
              width={isMobileScreen ? "375" : "560"}
              height={isMobileScreen ? "280" : "315"}
              src="https://www.youtube.com/embed/a3Sa2Yvu_Tc?si=sl93_U-pbZWW2wyS"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            />
          </Grid>
          <Grid item md={5}>
            <Typography>
              Mayor Rotering and councilmembers Blumberg and Stone have
              previously vowed no amendments for those seated in council, yet as
              the days have turned to weeks since Hoobler's resignation, still
              no vote is cast upon the agenda.
            </Typography>
          </Grid>
          <Grid item md={7}>
            <iframe
              width={isMobileScreen ? "375" : "560"}
              height={isMobileScreen ? "280" : "315"}
              src="https://www.youtube.com/embed/Tj8kohHujAk?si=2yAid-2OCrt9BDJR"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            />
          </Grid>
          <Grid item md={5}>
            <Typography>
              Act 3: The Echo of Follies. For 4 years the Mordini family have
              been battling the developer of the Albion apartments. Instead of
              placing his commercial ac units on the roof of the building,
              they've been placed on the side of the building. 43 commercial AC
              units blowing outside of the Mordini family's bedroom windows for
              the past FOUR years. Recently the city had played with the idea of
              amending the sound ordinance in favor of the developer! You can't
              make this stuff up!{" "}
              <a
                rel="noreferrer"
                href="https://www.youtube.com/live/uHf2WgSzy8o?t=1708s"
                target="_blank"
              >
                Listen here to the Mordini family's cry for help
              </a>
            </Typography>
          </Grid>
          <Grid item md={7}>
            <iframe
              width={isMobileScreen ? "375" : "560"}
              height={isMobileScreen ? "280" : "315"}
              src="https://www.youtube.com/embed/rh3rd64SAr0?si=FKWJxRZwD2GVFcaV"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default Links
