import React, { useState, useEffect } from "react"
import {
  Box,
  Button,
  Typography,
  Modal,
  TextField,
  Checkbox,
  FormControlLabel,
  Rating,
  Grid,
} from "@mui/material"
import axios from "axios"

// Styles for the modal
const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "8px",
  boxShadow: 24,
  p: 4,
}

const RateTaxPlanModal = (props) => {
  // State for modal, rating, and form data
  const [rating, setRating] = useState(0)
  const [isAnonymous, setIsAnonymous] = useState(true)
  const [fullName, setFullName] = useState("")
  const [age, setAge] = useState(null)
  const [comments, setComments] = useState("")
  const [hasSubmitted, setHasSubmitted] = useState(false)

  // Check if the user has already submitted feedback
  
  useEffect(() => {
    const feedbackId = localStorage.getItem(props.plan)
    if (feedbackId) {
      setHasSubmitted(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSubmit = async (e) => {
    e.preventDefault()

    // Don't allow submission if feedback already exists
    if (hasSubmitted) {
      alert("You have already submitted feedback.")
      return
    }
    const feedbackData = {
      rating,
      isAnonymous,
      fullName: isAnonymous ? "Anonymous" : fullName,
      age,
      comments,
      plan: props.plan,
    }
    try {
      // Make a POST request to the feedback route using axios
      const response = await axios.post(
        "https://move-hp-forward.onrender.com/feedback/add",
        feedbackData
      )
      // Store the unique feedback ID in local storage
      console.log({ response })
      const feedback = true // Assuming the response contains an _id
      localStorage.setItem(props.plan, feedback)

      // Mark as submitted to prevent further submissions
      setHasSubmitted(true)
      setComments("")
      setFullName("")
      setIsAnonymous(true)
      setRating(0)
      setAge(null)
    } catch (err) {
      console.error("Error submitting feedback:", err)
      alert("Error submitting feedback. Please try again.") // Notify the user
    }
    props.handleClose() // Close the modal after submission
  }
  return (
    <Box>
      {/* Modal with the form */}
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="rate-tax-plan-title"
        aria-describedby="rate-tax-plan-description"
      >
        <Box sx={modalStyle} component="form" onSubmit={handleSubmit}>
          {!hasSubmitted ? (
            <>
              <Typography
                id="rate-tax-plan-title"
                variant="h6"
                component="h2"
                mb={2}
              >
                Rate the Plan
              </Typography>
              <Typography component="legend">Your Rating:</Typography>
              <Rating
                name="tax-plan-rating"
                value={rating}
                onChange={(event, newValue) => {
                  setRating(newValue)
                }}
                sx={{ margin: "1rem" }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isAnonymous}
                    onChange={(e) => setIsAnonymous(e.target.checked)}
                  />
                }
                label="Submit Anonymously"
                sx={{ mt: 2 }}
              />
              {!isAnonymous && (
                <TextField
                  fullWidth
                  label="Full Name"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  sx={{ mt: 2 }}
                  required={!isAnonymous}
                />
              )}
              <TextField
                fullWidth
                type="number"
                label="Age (Optional)"
                value={age}
                onChange={(e) => setAge(e.target.value)}
                sx={{ mt: 2 }}
                required={false}
              />
              <TextField
                fullWidth
                label="Comments (Optional)"
                multiline
                rows={3}
                value={comments}
                onChange={(e) => setComments(e.target.value)}
                sx={{ mt: 2 }}
              />{" "}
            </>
          ) : (
            <Typography variant="h6" component="h2" mb={2}>
              Thanks for rating this
            </Typography>
          )}

          {/* Submit Button */}
          <Grid
            container
            justifyContent="flex-end"
            sx={{
              mt: 3,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Button
              color="error"
              variant="contained"
              onClick={() => props.handleClose()}
            >
              Close
            </Button>
            {!hasSubmitted && (
              <Button type="submit" variant="contained" color="primary">
                Submit
              </Button>
            )}
          </Grid>
        </Box>
      </Modal>
    </Box>
  )
}

export default RateTaxPlanModal
