import React from "react"
import { Line } from "react-chartjs-2"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js"
import { Box, useMediaQuery, useTheme } from "@mui/material";

// Register the necessary Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)

const RevenueComparison = () => {
    // Use MUI's theme and media query to detect mobile screens
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  // Data for the chart
  const data = {
    labels: ["$15M", "$20M", "$25M", "$30M", "$35M", "$40M", "$45M", "$50M"],
    datasets: [
      {
        label: "5% Donation",
        data: [0.75, 1.0, 1.25, 1.5, 1.75, 2.0, 2.25, 2.5],
        borderColor: "rgba(255, 61, 61, 1)",
        backgroundColor: "rgba(255, 61, 61, 0.2)",
        fill: false,
        tension: 0.1,
        pointRadius: isMobile ? 6 : 4, // Larger points on mobile
        pointHoverRadius: isMobile ? 10 : 6, // Larger hover effect on mobile
        pointHitRadius: isMobile ? 20 : 10, // Easier to tap on mobile
      },
      {
        label: "Equitable Revenue Enhancement Plan",
        data: [3.575, 4.23, 4.885, 5.54, 6.195, 6.85, 7.505, 8.16],
        borderColor: "rgba(88, 194, 86, 1)",
        backgroundColor: "rgba(88, 194, 86, 0.2)",
        fill: false,
        tension: 0.1,
        pointRadius: isMobile ? 6 : 4, // Larger points on mobile
        pointHoverRadius: isMobile ? 10 : 6, // Larger hover effect on mobile
        pointHitRadius: isMobile ? 20 : 10, // Easier to tap on mobile
      },
    ],
  }

  // Options for the chart
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Revenue Comparison",
        font: {
          size: 18,
        },
        padding: {
          top: 10,
          bottom: 2,
        },
      },
      subtitle: {
        // SubTitle is correctly named and registered
        display: true,
        text: "Using Ravinia Festival as ONLY an example",
        font: {
          size: 10,
          style: "italic",
        },
        padding: {
          top: 5,
          bottom: 10,
        },
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const value = context.raw // Access the data value
            return "$" + value + " million" // Append '%' to the value
          },
        },
      },
    },
    scales: {
      y: {
        title: {
          display: true,
          text: "Revenue (Millions USD)",
        },
        ticks: {
          maxTicksLimit: 10, // Ensure at least 10 data points on the Y-axis
          callback: function (value) {
            return `$${value}M` // Add a 'M' suffix to denote millions
          },
        },
      },
      x: {
        title: {
          display: true,
          text: "Revenue (Millions USD)",
        },
      },
    },
  }

  return (
    <Box sx={{ width: { xs: "100%", sm: "600px" }, height: "300px" }}>
      <Line data={data} options={options} />{" "}
    </Box>
  )
}

export default RevenueComparison
