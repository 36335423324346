import { Grid, Typography, useMediaQuery, useTheme, Box } from "@mui/material"
import React from "react"
import { Helmet } from "react-helmet"

const Saga = () => {
  const theme = useTheme()
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"))
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        alignItems: "center",
        padding: "2rem",
        gap: "2rem",
      }}
    >
      <Helmet>
        <title>The Saga</title>
      </Helmet>
      <Grid
        container
        sx={{
          justifyContent: "center",
          alignItems: "center",
          width: { md: "75%" },
        }}
      >
        <Grid item xs={12}>
          <Typography fontSize={40} fontWeight={900} textAlign={"center"}>
            The Complete Saga of the Amendment of the Antiquated Liquor Law of
            Highland Park
          </Typography>
        </Grid>
        <Grid item md={5}>
          <Typography>
            Here is a video from the recent Highland Park City Hall meeting
            (2/12/2024), marking the onset of much of the controversy. Notably,
            an entire minute featuring local resident Levi Kane was omitted in
            the official release. Kane has responded by sharing the complete,
            unedited version of his three-minute statement, also available for
            viewing below.
          </Typography>
        </Grid>
        <Grid item md={7}>
          <iframe
            width={isMobileScreen ? "375" : "560"}
            height={isMobileScreen ? "280" : "315"}
            src="https://www.youtube.com/embed/gd84Ak0U0IA?si=XGTDt-XoKGqLXPua&cc_load_policy=1&modestbranding=1&rel=0"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowFullScreen
          />

          <iframe
            width={isMobileScreen ? "375" : "560"}
            height={isMobileScreen ? "280" : "315"}
            src="https://www.youtube.com/embed/22TbNc6Qf80?si=13QTyPe97JAglqIO&cc_load_policy=1&modestbranding=1&rel=0"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowFullScreen
          />
        </Grid>
        <Grid item md={5}>
          <Typography>
            An additional link from April 10, 2024, reveals a significantly
            smaller yet unanimously supportive crowd advocating for the
            amendment of the law. One key provision approved during this meeting
            grants every business the right to obtain a liquor permit. However,
            this comes with a significant stipulation: business owners will be
            precluded from running for or holding any city office. Despite the
            potential implications of such a legislative change, the assembly
            opted to pass the measure without dedicating sufficient time—two
            weeks—to fully evaluate the potential advantages and disadvantages
          </Typography>
        </Grid>
        <Grid item md={7}>
          <iframe
            width={isMobileScreen ? "375" : "560"}
            height={isMobileScreen ? "280" : "315"}
            src="https://www.youtube.com/embed/U5jh2ltKcMQ?si=AjvTpeGmgMQv1L15&cc_load_policy=1&modestbranding=1&rel=0"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowFullScreen
          />
        </Grid>
        <Grid item md={5}>
          <Typography>
            The video featured below captures a poignant moment from April 24th,
            2024, where approximately 200-250 residents of Highland Park
            convened at the city hall. They united in a heartfelt appeal for the
            council to convene a special session aimed at amending the liquor
            law before the impending resignation of Jeff Hoobler on April 30th.
            This edited version of the council meeting has been carefully
            curated to highlight only those segments that pertain to
            contributions from the public, emphasizing the community's active
            engagement in civic matters.
          </Typography>
        </Grid>
        <Grid item md={7}>
          <iframe
            width={isMobileScreen ? "375" : "560"}
            height={isMobileScreen ? "280" : "315"}
            src="https://www.youtube.com/embed/ztrEOztFsvc?si=hFlmiwFB9JfBjj9t&cc_load_policy=1&modestbranding=1&rel=0&start=946"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowFullScreen
          />
        </Grid>
        <Grid item md={5}>
          <Typography>
            Act 1: Kane sets the record straight. With a comprehensive total of
            1,491 pages of FOIA documents spanning from January 1 to April 30,
            the day of Jeff's resignation, the latter half displayed in this
            video reveals merely two sources of opposition. The Mayor and her
            two associates have undeniably exposed themselves as corrupt.
          </Typography>
        </Grid>
        <Grid item md={7}>
          <iframe
            width={isMobileScreen ? "375" : "560"}
            height={isMobileScreen ? "280" : "315"}
            src="https://www.youtube.com/embed/a3Sa2Yvu_Tc?si=sl93_U-pbZWW2wyS&cc_load_policy=1&modestbranding=1&rel=0"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowFullScreen
          />
        </Grid>
        <Grid item md={5}>
          <Typography>
            Act 2: Mayor Rotering and councilmembers Blumberg and Stone have
            previously vowed no amendments for those seated in council, yet as
            the days have turned to weeks since Hoobler's resignation, still no
            vote is cast upon the agenda.
          </Typography>
        </Grid>
        <Grid item md={7}>
          <iframe
            width={isMobileScreen ? "375" : "560"}
            height={isMobileScreen ? "280" : "315"}
            src="https://www.youtube.com/embed/Tj8kohHujAk?si=2yAid-2OCrt9BDJR&cc_load_policy=1&modestbranding=1&rel=0"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowFullScreen
          />
        </Grid>
        <Grid item md={5}>
          <Typography>
            Act 3: The Echo of Follies. For 4 years the Mordini family have been
            battling the developer of the Albion apartments. Instead of placing
            his commercial ac units on the roof of the building, they've been
            placed on the side of the building. 43 commercial AC units blowing
            outside of the Mordini family's bedroom windows for the past FOUR
            years. Recently the city had played with the idea of amending the
            sound ordinance in favor of the developer! You can't make this stuff
            up!{" "}
            <a
              rel="noreferrer"
              href="https://www.youtube.com/live/uHf2WgSzy8o?t=1708s"
              target="_blank"
            >
              Listen here to the Mordini family's cry for help
            </a>
          </Typography>
        </Grid>
        <Grid item md={7}>
          <iframe
            width={isMobileScreen ? "375" : "560"}
            height={isMobileScreen ? "280" : "315"}
            src="https://www.youtube.com/embed/rh3rd64SAr0?si=FKWJxRZwD2GVFcaV&cc_load_policy=1&modestbranding=1&rel=0"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowFullScreen
          />
        </Grid>
        <Grid item md={5}>
          <Typography>
            Act 4: It has been revealed that the individual appointed to Jeff
            Hoobler's rightful seat is not a lawyer, but rather a Human
            Resources specialist. With Jeff Hoobler ousted from the city
            council, the Mayor and her allies seized the opportunity to appoint
            a replacement of their choosing until the next election. Their
            intention was to fill Jeff's position with another young, dynamic
            individual, yet they clearly missed the mark on both qualification
            and value. The newly appointed councilmember, Barisa Bruckman, had
            the chance to champion a policy allowing any citizen with a liquor
            license to serve on the city council. Instead, she opted to side
            with the trio of Mayor Rotering, Kim Stone, and Tony Blumberg,
            thereby neglecting the interests of Highland Park's residents.
          </Typography>
        </Grid>
        <Grid item md={7}>
          <iframe
            width={isMobileScreen ? "375" : "560"}
            height={isMobileScreen ? "280" : "315"}
            src="https://www.youtube.com/embed/185Z0XTV7Co?si=-klHhtOr-IbT7fJq&cc_load_policy=1&modestbranding=1&rel=0"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowFullScreen
          />
        </Grid>
        <Grid item md={5}>
          <Typography>
            Act 5: The 5th and final act is a stirring invocation for greatness.
            Levi urges the council and the townspeople to aspire for more than
            mediocrity. He calls for a council filled with visionaries and
            entrepreneurs, who can transform Highland Park into a flourishing
            community. Levi critiques the current council’s stifling bureaucracy
            and advocates for a more business-friendly environment. The act ends
            on a poignant note, leaving the audience to ponder whether Highland
            Park will ever see a leader like Levi again, or if the town is
            doomed to remain in the grip of its current rulers.
          </Typography>
        </Grid>
        <Grid item md={7}>
          <iframe
            width={isMobileScreen ? "375" : "560"}
            height={isMobileScreen ? "280" : "315"}
            src="https://www.youtube.com/embed/Wdvmn7S0fiY?si=ovngNB7rtJeIYqRe&cc_load_policy=1&modestbranding=1&rel=0"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowFullScreen
          />
        </Grid>
        <Grid item md={5}>
          <Typography>
            The Encore: Levi presents his Equitable Revenue Enhancement Plan to
            the Highland Park City Council, calling for new taxes on large
            amusement entities grossing over $15 million annually. His proposal
            includes a 2.1% sales tax for schools, a carbon emissions tax , a 1%
            naming rights tax , and a 10% amusement tax , including compensation
            for lost property taxes. He criticizes the festival’s current 5%
            revenue donation as insufficient and argues that his plan could
            generate over 5% of the city’s annual budget , urging the council to
            support local businesses and adopt the plan.
          </Typography>
        </Grid>
        <Grid item md={7}>
          <iframe
            width={isMobileScreen ? "375" : "560"}
            height={isMobileScreen ? "280" : "315"}
            src="https://www.youtube.com/embed/3aIDuII7NUY?si=ovngNB7rtJeIYqRe&cc_load_policy=1&modestbranding=1&rel=0"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowFullScreen
          />
        </Grid>
        <Grid item md={5}>
          <Typography>
            Plan B: Levi returns to present The BEACON: The Budget Enhancement
            Community Optimization Network Plan. Large scale amusement entities
            grossing over $15 million annually would be subject to a carbon
            emissions tax, a 1% naming rights tax, and a 12% amusement tax
            including compensation for lost property taxes. Funds from the
            General Fund of the Amusement Tax would also fund the Mayor's
            increased salary.
          </Typography>
        </Grid>
        <Grid item md={7}>
          <iframe
            width={isMobileScreen ? "375" : "560"}
            height={isMobileScreen ? "280" : "315"}
            src="https://www.youtube.com/embed/RFQSbc3EW4s?si=ovngNB7rtJeIYqRe&cc_load_policy=1&modestbranding=1&rel=0"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowFullScreen
          />
        </Grid>
        <Grid item md={5}>
          <Typography>
            Plan C: Levi returns to present The RISE Plan: The Revenue
            Initiatives for Sustainable Equity Plan. Large scale cultural
            institutions and/or amusement entities grossing over $15 million
            annually would be subject to a carbon emissions tax, a 1% naming
            rights tax, and a 12% amusement tax including compensation for lost
            property taxes. The City Manager's Salary would also be adjusted
            from $10.50/citizen to the average amount of $2.75/citizen.
          </Typography>
        </Grid>
        <Grid item md={7}>
          <iframe
            width={isMobileScreen ? "375" : "560"}
            height={isMobileScreen ? "280" : "315"}
            src="https://www.youtube.com/embed/EgUQEgVPJRc?si=ovngNB7rtJeIYqRe&cc_load_policy=1&modestbranding=1&rel=0"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowFullScreen
            style={{ backgroundColor: "grey" }}
          />
        </Grid>
        <Grid item md={5}>
          <Typography fontSize={32} fontWeight={900}>
            Closing Arguments
          </Typography>
          <Typography>
            Are the Mayor and her cronies so petty that they're rejecting these
            revenue plans just because they're coming from me? Or is there
            something else at play here?
          </Typography>
        </Grid>
        <Grid item md={7}>
          <iframe
            width={isMobileScreen ? "375" : "560"}
            height={isMobileScreen ? "280" : "315"}
            src="https://www.youtube.com/embed/aqJ9HRxd6i0?si=ovngNB7rtJeIYqRe&cc_load_policy=0&modestbranding=1&rel=0"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowFullScreen
            style={{ backgroundColor: "grey" }}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default Saga
