import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Typography,
} from "@mui/material"
import React from "react"
import FindInPageIcon from "@mui/icons-material/FindInPage"
import { Link } from "react-router-dom"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import faqInfo from "../faq.json"

const Faqs = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        alignItems: "center",
        padding: "2rem",
        gap: "2rem",
      }}
    >
      <Link to={"/subscribe"}>
        <Button variant="contained" color="success">
          <Typography fontWeight={600}>Stay up to date!</Typography>
        </Button>
      </Link>
      <Typography
        textAlign={"center"}
        fontSize={"36px"}
        sx={{
          textShadow: "2px 2px 4px rgba(0,0,0,0.5)",
          fontWeight: 600,
          backgroundColor: "#84A586",
          borderRadius: "15px",
          padding: "12px",
        }}
      >
        Highland Park’s antiquated liquor licensing law: An arbitrary
        restriction on voter choice and threat to outcome of local election
      </Typography>
      <Box
        sx={{
          marginTop: "20px",
          display: "flex",
          flexDirection: "column",
          gap: ".875rem",
        }}
      >
        <Box
          sx={{
            border: "2px black solid",
            borderRadius: "15px",
            padding: "1rem",
          }}
        >
          <Typography
            sx={{ fontSize: "24px", fontWeight: 600, textAlign: "center" }}
          >
            !Recent Updates!
          </Typography>
          <Typography>{faqInfo.update}</Typography>
        </Box>
        <Box>
          {faqInfo.faqs.map((faq, index) => (
            <Accordion key={index}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: "1.5rem",

                    color: "black",
                  }}
                >
                  {faq.title}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>{faq.description}</AccordionDetails>
              {faq.secondary && (
                <AccordionDetails>{faq.secondary}</AccordionDetails>
              )}
              {faq.tertiary && (
                <AccordionDetails>{faq.tertiary}</AccordionDetails>
              )}
              {faq.numberedList &&
                faq.numberedList.map((listItem, index) => (
                  <AccordionDetails key={index}>
                    {index + 1}. {listItem}
                  </AccordionDetails>
                ))}
            </Accordion>
          ))}
        </Box>
      </Box>
      <a
        href="./assets/FAQs_about_the_controversy_involving_Highland_Park.pdf"
        download={"FAQs_about_the_controversy_involving_Highland_Park"}
        target="_blank"
      >
        <Button
          endIcon={<FindInPageIcon />}
          variant="contained"
          color="success"
        >
          Download our FAQ's!
        </Button>
      </a>
    </Box>
  )
}

export default Faqs
