import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  TextField,
  Typography,
} from "@mui/material"
import React, { useState } from "react"
import axios from "axios"
import { Helmet } from "react-helmet"

const Subscribe = () => {
  const [supporter, setSupporter] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
  })
  const [disabledButton, setDisabledButton] = useState(false)
  const [errorDialogOpen, setErrorDialogOpen] = useState(false)
  const inputFields = [
    { label: "First Name", stateKey: "firstName" },
    { label: "Last Name", stateKey: "lastName" },
    { label: "Email", stateKey: "email" },
    { label: "Phone (optional)", stateKey: "phoneNumber" },
  ]
  const handleSubmit = async (e) => {
    setDisabledButton(true)
    e.preventDefault()
    // Post request to add user
    await axios
      .post("https://move-hp-forward.onrender.com/supporters/add", supporter)
      .then((res) => {
        console.log("Posting: ", res.data)
        setDisabledButton(false)
        setSupporter({
          firstName: "",
          lastName: "",
          email: "",
          phoneNumber: "",
        })
      })
      .catch((error) => {
        console.log("Uh oh! You have an error, dude: ", error)
        setSupporter({
          firstName: "",
          lastName: "",
          email: "",
          phoneNumber: "",
        })
        setDisabledButton(false)
        setErrorDialogOpen(true)
      })
  }
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
          alignItems: "center",
          padding: "2rem",
          gap: "2rem",
        }}
      >
        <Helmet>
          <title>Subscribe</title>
        </Helmet>
        <Typography textAlign={"center"}>
          <span
            style={{
              textDecoration: "underline",
              fontWeight: 900,
              fontSize: 24,
            }}
          >
            Please contact our camp at:
          </span>{" "}
          <a
            href={`mailto:info@amendhp.com`}
            style={{ fontWeight: 900, fontSize: 24 }}
          >
            info@amendhp.com
          </a>
        </Typography>
        <Box sx={{ width: { xs: "90%", md: "75%" } }}>
          <Typography textAlign={"center"}>
            We cannot achieve this alone. To truly revitalize our town, we
            require your support. Please provide your contact details below to
            remain informed about our collective efforts to{" "}
            <strong>amend and revive Highland Park.</strong> All contact info
            will remain private.
          </Typography>
          <form onSubmit={handleSubmit}>
            <FormControl fullWidth>
              {inputFields.map((field, index) => (
                <TextField
                  key={field.stateKey}
                  fullWidth
                  required={index !== inputFields.length - 1}
                  id={`outlined-${field.stateKey}`}
                  label={field.label}
                  value={
                    index === inputFields.length - 1 &&
                    !supporter[field.stateKey]
                      ? "1-999999999" // Prepopulate last field
                      : supporter[field.stateKey]
                  }
                  type={field.label === "Email" ? "email" : "text"}
                  onChange={(event) =>
                    setSupporter({
                      ...supporter,
                      [field.stateKey]: event.target.value,
                    })
                  }
                  sx={{ margin: ".5rem 0" }}
                />
              ))}
              <Button
                sx={{ borderRadius: "20px", marginTop: ".5rem" }}
                variant="contained"
                color="success"
                type="submit"
                // onClick={() => setDisabledButton(true)}
                disabled={disabledButton}
              >
                Stay in the loop
              </Button>
            </FormControl>
          </form>
        </Box>
      </Box>

      <Dialog open={errorDialogOpen}>
        <DialogTitle>WHOOPS!</DialogTitle>
        <DialogContent>
          Looks like we already have your info. But thanks for double checking!
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setErrorDialogOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default Subscribe
