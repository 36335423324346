import { Box, Typography } from "@mui/material"
import React, { useState, useEffect } from "react"
import { Helmet } from "react-helmet"
import { Link } from "react-router-dom"
import YouTube from "react-youtube" // Import YouTube component

const Story = () => {
  const [showVideo, setShowVideo] = useState(false) // New state for showing video

  useEffect(() => {
    // Scroll to the bottom of the page when the component mounts
    window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" })
  }, [])

  return (
    <Box
      sx={{
        paddingY: "20px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        textAlign: "center",
        paddingX: { xs: "20px", sm: "20%" },
        gap: "20px",
      }}
    >
      <Helmet>
        <title>The Story</title>
      </Helmet>
      <Typography fontSize={50} fontWeight={900}>
        Vote YES!
      </Typography>
      <Typography sx={{ marginTop: "-30px" }}>
        (To allow all citizens, including liquor license holders, to serve as
        elected officials)
      </Typography>
      <Typography>
        There's a lot going on here with this story and we've done our best to
        section things out accordingly. Below you'll find 3 options on how to
        follow along.
      </Typography>
      <Typography>
        Despite all surrounding municipalities amending their antiquated laws to
        allow liquor license holders to serve as an elected official, Highland
        Park has yet to do the same. In October 2023, the Ravinia Brewery had
        recently come under legal attack by the Ravinia Music Festival as they
        decided to file suit against the local Brewery, over the name Ravinia,
        in the neighborhood of Ravinia. Yes - you read that correctly.
        Conveniently, the co-owner of the brewery, Jeff Hoobler had recently won
        a seat on the City Council by landslide. This is something that the
        Mayor had never endorsed. In January 2024, after the Mayor signed off to
        renew the brewery's liquor license, "SOMEBODY" dug up an archaic law
        from the prohibition era that states any person who has a liquor license
        cannot serve as an elected official. This meant that Hoobler had to
        either give up his council seat or his brewery. A few short weeks later,
        the potential to amend the law, to save both Hoobler's seat and his
        brewery was put on the City Council's agenda. A large gathering of
        citizens showed up in support of Jeff Hoobler and the brewery in hopes
        that the council would amend the antiquated liquor law...
      </Typography>
      <Box
        sx={{
          paddingX: { sm: "10%" },
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: "20px",
        }}
      >
        <Link
          style={{ textDecoration: "none" }}
          to={"/prologue"}
          onClick={() =>
            window.scrollTo({
              top: 0,
              behavior: "smooth", // Smooth scrolling for better user experience
            })
          }
        >
          <Box
            sx={{
              border: "2px solid black",
              borderRadius: "20px",
              padding: "12px",
              backgroundColor: "#dbdbdb",
              color: "black",
            }}
          >
            <Typography>
              <strong>Click here for the Prologue:</strong> Starting from
              February 12th, 2024 with Kane's first public appearance in
              advocacy of allowing Jeff Hoobler to retain his council seat
              despite the fact that he had a liquor license. Although still new
              to the city, he knew that what was happening was unjust. After
              realizing that anybody had the chance to speak, he decided to
              stand up and say something.
            </Typography>
          </Box>
        </Link>
        <Link
          style={{ textDecoration: "none" }}
          to={"/bshakes"}
          onClick={() =>
            window.scrollTo({
              top: 0,
              behavior: "smooth", // Smooth scrolling for better user experience
            })
          }
        >
          <Box
            sx={{
              border: "2px solid black",
              borderRadius: "20px",
              padding: "12px",
              backgroundColor: "#dbdbdb",
              color: "black",
            }}
          >
            <Typography>
              <strong>Click here for the Masquerade of Folly:</strong> After
              being told that his direct nature and utter lack of eloquence and
              refinement was turning people off, he decided to go back to the
              drawing board. Although frustrated with the split decisions of the
              council and ultimately being treated like and shooed away like a
              peasant, he decided to try something different.
            </Typography>
          </Box>
        </Link>
        <Link
          style={{ textDecoration: "none" }}
          to={"/plan"}
          onClick={() =>
            window.scrollTo({
              top: 0,
              behavior: "smooth", // Smooth scrolling for better user experience
            })
          }
        >
          <Box
            sx={{
              border: "2px solid black",
              borderRadius: "20px",
              padding: "12px",
              backgroundColor: "#dbdbdb",
              color: "black",
            }}
          >
            <Typography>
              <strong>Click here for the Revenue Plans:</strong> After spending
              part of the spring and an entire summer trolling the wrong-doings
              of the Mayor and her allies, he had a chance to meet with the
              Mayor and the City Manager over coffee to discuss details on how
              we could all work together. Or so we all thought...
            </Typography>
          </Box>
        </Link>
        {!showVideo && (
          <Box
            onClick={() => setShowVideo(true)}
            sx={{
              border: "2px solid black",
              borderRadius: "20px",
              padding: "12px",
              backgroundColor: "#dbdbdb",
              color: "black",
              cursor: "pointer",
            }}
          >
            <Typography fontSize={32} fontWeight={900}>
              Click here for the Closing Arguments:
            </Typography>
            <Typography>
              We've effectively drug this thing out from February 12th until
              October 28th - One week before the national election. On the
              TOP-Middle section on the back of the ballot, voters have the
              opportunity to vote YES to allow all qualified citizens (including
              those with a liquor license) to serve as elected officials.
            </Typography>
          </Box>
        )}
        {/* YouTube video iframe */}
        {showVideo && (
          <Box width="100%">
            <YouTube
              style={{ flexGrow: "1" }}
              videoId="aqJ9HRxd6i0"
              opts={{
                width: "100%",
                playerVars: {
                  autoplay: 1,
                  cc_load_policy: 0,
                },
              }}
            />
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default Story
