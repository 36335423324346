import { Button } from "@mui/material"
import React from "react"
import { Link } from "react-router-dom"
import ArrowBackIcon from "@mui/icons-material/ArrowBack" // Import back arrow icon

const Sorry = () => {
  return (
    <div>
      Sorry but this plan isn't quite ready yet!
      {/* Floating Back Button */}
      <Link to="/plan" style={{ textDecoration: "none" }}>
        <Button
          variant="contained"
          color="secondary"
          startIcon={<ArrowBackIcon />}
          sx={{
            position: "fixed",
            top: "16px",
            left: "16px",
            zIndex: 1000, // Ensure it floats above other content
          }}
        >
          Back
        </Button>
      </Link>
    </div>
  )
}

export default Sorry
