import React from "react"
import { Pie } from "react-chartjs-2"
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js"
import { Box } from "@mui/material"
import { containerStyle } from "./chartStyles"

// Register necessary components for the Pie chart
ChartJS.register(ArcElement, Tooltip, Legend)

const AmusementPieChart = () => {
  // Data for the pie chart
  const data = {
    labels: [
      "General Fund",
      "Special Purpose Fund",
      "Debt Service/Capital Projects",
    ],
    datasets: [
      {
        label: "Amusement Tax Distribution",
        data: [60, 20, 20], // Percentage breakdown
        backgroundColor: [
          "rgba(75, 192, 192, 0.6)", // Color for General Fund
          "rgba(255, 159, 64, 0.6)", // Color for Special Purpose Fund
          "rgba(153, 102, 255, 0.6)", // Color for Debt Service/Capital Projects
        ],
        borderColor: [
          "rgba(75, 192, 192, 1)",
          "rgba(255, 159, 64, 1)",
          "rgba(153, 102, 255, 1)",
        ],
        borderWidth: 1,
      },
    ],
  }

  // Options for the pie chart
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Amusement Tax Distribution",
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const value = context.raw // Access the data value
            return value + "%" // Append '%' to the value
          },
        },
      },
    },
  }
  // Styling to shrink the chart to 50% on desktop
  

  return (
    <Box sx={containerStyle}>
      <Pie data={data} options={options} />{" "}
    </Box>
  )
}

export default AmusementPieChart
