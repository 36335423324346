import { Box, Typography } from "@mui/material"
import React from "react"
import { useLocation } from "react-router-dom"

const Banner = () => {
  const location = useLocation()

  return (
    <Box
      sx={{
        height: "5rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#84A586",
      }}
    >
      {location.pathname === "/ravinia" ? (
        <Typography
          color={"white"}
          sx={{
            fontSize: "36px",
            fontWeight: 900,
            textShadow: "4px 4px 4px rgba(0,0,0,0.8)",
          }}
        >
          !!STOP THE SUIT!!
        </Typography>
      ) : (
        <Typography
          color={"white"}
          sx={{
            fontSize: "36px",
            fontWeight: 600,
            textShadow: "2px 2px 4px rgba(0,0,0,0.8)",
          }}
        >
          Amend HP
        </Typography>
      )}
    </Box>
  )
}

export default Banner
