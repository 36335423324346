import React, { useState } from "react"
import {
  Button,
  Modal,
  Box,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material"
import axios from "axios"

const Emailer = () => {
  const [open, setOpen] = useState(false)
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    signCount: "",
    address: "",
  })
  const [sending, setSending] = useState(false)
  const [reqHasBeenSent, setReqHasBeenSent] = useState(false)

  // Toggle modal
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  // Handle form changes
  const handleChange = (event) => {
    const { name, value } = event.target
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault()
    setSending(true)
    try {
      const response = await axios.post(
        "https://move-hp-forward.onrender.com/send-email",
        formData
      )

      if (response.status === 200) {
        setReqHasBeenSent(true)
        handleClose()
        setFormData({
          firstName: "",
          lastName: "",
          signCount: "",
          address: "",
        })
      } else {
        alert("There was a problem sending your request.")
      }
    } catch (error) {
      console.error("Error sending email:", error)
    }
    setSending(false)
  }

  return (
    <div>
      <Button
        variant="contained"
        color={reqHasBeenSent ? "success" : "primary"}
        onClick={handleOpen}
      >
        {reqHasBeenSent ? "Request more Merch!" : "Campaign Sign Drop-Off"}
      </Button>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{ p: 4, backgroundColor: "white", margin: "auto", width: 300 }}
        >
          <h2>Campaign Sign Drop-Off</h2>
          <form onSubmit={handleSubmit}>
            <TextField
              label="First Name"
              name="firstName"
              fullWidth
              margin="normal"
              value={formData.firstName}
              onChange={handleChange}
              required
            />
            <TextField
              label="Last Name"
              name="lastName"
              fullWidth
              margin="normal"
              value={formData.lastName}
              onChange={handleChange}
              required
            />
            <FormControl fullWidth margin="normal">
              <InputLabel>Number of Signs</InputLabel>
              <Select
                name="signCount"
                value={formData.signCount}
                onChange={handleChange}
                required
              >
                {[1, 2, 3, 4, 5].map((num) => (
                  <MenuItem key={num} value={num}>
                    {num}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              label="Address"
              name="address"
              fullWidth
              margin="normal"
              value={formData.address}
              onChange={handleChange}
              required
            />
            <Button variant="contained" type="submit" disabled={sending}>
              Submit
            </Button>
          </form>
        </Box>
      </Modal>
    </div>
  )
}

export default Emailer
