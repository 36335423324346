import { Box, Button, useMediaQuery, useTheme } from "@mui/material"
import React from "react"
import { Link } from "react-router-dom"

const Footer = () => {
  const theme = useTheme()
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"))
  return (
    <Box
      sx={{
        backgroundColor: "#84A586",
        display: "flex",
        justifyContent: "space-between",
        padding: isMobileScreen ? "2rem 0.5rem 2rem 0.5rem" : "2rem",
        gap: "12px",
      }}
    >
      <Box>
        <Link to={"/plan"}>
          <Button
            variant="contained"
            color="success"
            onClick={() => window.scrollTo(0, 0)}
          >
            Plan of Action
          </Button>
        </Link>
      </Box>
      <Box>
        <Link to={"/subscribe"}>
          <Button variant="contained" color="success">
            Subscribe
          </Button>
        </Link>
      </Box>
    </Box>
  )
}

export default Footer
