import React from "react"
import { Box, Button, Typography } from "@mui/material"
import HpBackground from "../assets/Highland_Park_Station.jpg"
// import FollowTheSignsIcon from "@mui/icons-material/FollowTheSigns"
import { Link } from "react-router-dom"
// import contacts from "../contacts.json"
// import email from "../email.json"
// import axios from "axios"

const Home = () => {
  // const tallyHoe = {
  //   tallyMark: "From Home Screen",
  // }
  // const addTallyMark = async () => {
  //   await axios
  //     .post("https://move-hp-forward.onrender.com/tallies/add-tally", tallyHoe)
  //     .then((res) => {
  //       console.log("Tallying: ", res.data)
  //     })
  //     .catch((err) => console.log(err))
  // }
  return (
    <Box
      sx={{
        backgroundImage: `url("${HpBackground}")`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        height: "100vh",
        backgroundPosition: "center",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          marginTop: "3rem",
          width: { xs: "80%", md: "50%" },
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
          alignItems: "center",
          gap: "1rem",
          justifyContent: "space-between",
          padding: "0 0 3rem 0",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
            alignItems: "center",
            gap: "1rem",
          }}
        >
          {/* <Link to={"/rally"}>
            <Button
              sx={{ maxWidth: "300px", gap: ".75rem" }}
              variant="contained"
              color="error"
              size="large"
              endIcon={<FollowTheSignsIcon sx={{ transform: "scale(1.8)" }} />}
            >
              <Typography fontWeight={600}>Rally Info</Typography>
            </Button>
          </Link> */}
          <Link to={"/plan"}>
            {/* <a
            onClick={() => addTallyMark()}
            href={`mailto:${contacts[0].email}?subject=${email.subject}&body=${email.body}&cc=gneukirch@cityhpil.com`}
          > */}
            <Button
              sx={{ maxWidth: "300px", gap: ".75rem" }}
              variant="contained"
              color="success"
              size="large"
            >
              <Typography fontWeight={600} fontSize={"20px"}>
                Click here to Add 5% to the city's Budget
              </Typography>
            </Button>
            {/* </a> */}
          </Link>
        </Box>
        <Box>
          {/* <a
            href="https://raviniamusicfestival.com"
            target="_blank"
            rel="noreferrer"
          >
            <Button variant="contained">Link to background story here</Button>
          </a> */}
        </Box>
      </Box>
    </Box>
  )
}

export default Home
