import { Box, Chip, Typography } from "@mui/material"
import React from "react"
import contacts from "../contacts.json"
// import email from "../email.json"
import FastForwardIcon from "@mui/icons-material/FastForward"
import GavelIcon from "@mui/icons-material/Gavel"
import BalanceIcon from "@mui/icons-material/Balance"
import axios from "axios"
import { Helmet } from "react-helmet"

const Contact = () => {
  const tallyHoe = {
    tallyMark: "Contact City",
  }
  const addTallyMark = async () => {
    await axios
      .post("https://move-hp-forward.onrender.com/tallies/add-tally", tallyHoe)
      .then((res) => {
        console.log("Tallying: ", res.data)
      })
      .catch((err) => console.log(err))
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        alignItems: "center",
        padding: "2rem",
        gap: "2rem",
      }}
    >
      <Helmet>
        <title>Contact HP Council</title>
      </Helmet>
      <Typography textAlign={"center"}>
        <span
          style={{ textDecoration: "underline", fontWeight: 900, fontSize: 24 }}
        >
          Please contact our camp at:
        </span>{" "}
        <a
          href={`mailto:info@amendhp.com`}
          style={{ fontWeight: 900, fontSize: 24 }}
        >
          info@amendhp.com
        </a>
      </Typography>
      <Typography
        sx={{
          border: "1px solid black",
          borderRadius: "15px",
          padding: ".75rem",
        }}
      >
        We urge you to make your voices heard by those who hold the most
        power—our elected representatives. They are in service to us, having
        pledged to serve the public and uphold the community's best interests
        with integrity and dedication.
      </Typography>

      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
          gap: { xs: "4rem", md: "3rem" },
          flexWrap: "wrap",
        }}
      >
        {contacts.map((contact, index) => (
          <a
            onClick={() => addTallyMark()}
            href={`mailto:${contact.email}?cc=gneukirch@cityhpil.com`}
            style={{
              textDecoration: "none",
              color: index === 0 ? "blue" : "black",
            }}
          >
            <Box
              key={index}
              sx={
                index === 0 && {
                  border: "3px solid black",
                  padding: "1rem",
                  borderRadius: "15px",
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.2)", // Background color on hover
                  },
                }
              }
            >
              {index === 0 && (
                <Typography sx={{ fontWeight: 800, fontSize: "1.5rem" }}>
                  Contact the entire City Council here:
                </Typography>
              )}
              <Box
                sx={{ display: "flex", alignItems: "center", gap: ".75rem" }}
              >
                <Typography>{contact.name}</Typography>{" "}
                <Chip
                  sx={{
                    ".MuiChip-icon": { marginLeft: "40%" },
                  }}
                  icon={
                    contact.stance === "Stagnation" ? (
                      <GavelIcon />
                    ) : contact.stance === "Middle" ? (
                      <BalanceIcon />
                    ) : (
                      <FastForwardIcon />
                    )
                  }
                  color={
                    contact.stance === "Stagnation"
                      ? "error"
                      : contact.stance === "Middle"
                      ? "warning"
                      : "success"
                  }
                />
              </Box>
              <Typography>{contact.title}</Typography>

              <Typography sx={{ textDecoration: "underline" }}>
                {contact.email}
              </Typography>
            </Box>
          </a>
        ))}
      </Box>
    </Box>
  )
}

export default Contact
