import {
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  Box,
  Button,
} from "@mui/material"
import { useState, useEffect } from "react"
import acts from "../acts.json"
import { Helmet } from "react-helmet"

const Masquerade = () => {
  const theme = useTheme()
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"))
  const [player, setPlayer] = useState(null)

  // Load YouTube Iframe API
  const loadYouTubeIframeAPI = () => {
    if (!window.YT) {
      const script = document.createElement("script")
      script.src = "https://www.youtube.com/iframe_api"
      script.async = true
      script.defer = true
      document.body.appendChild(script)
    }
  }

  useEffect(() => {
    loadYouTubeIframeAPI()

    // Poll to check if the YT API is ready
    const checkYTReady = setInterval(() => {
      if (window.YT && window.YT.Player) {
        console.log("YouTube API is ready.")
        clearInterval(checkYTReady)

        // Initialize the player manually if the API is ready
        new window.YT.Player("youtube-player", {
          events: {
            onReady: (event) => {
              console.log("Player is ready.")
              setPlayer(event.target) // Save the player instance to state
            },
            onError: (event) => {
              console.log("Player error:", event)
            },
          },
          playerVars: {
            listType: "playlist",
            list: "PLywwFRgDVdblvhKD1tcxKnM2DK5wHmXCr", // Your playlist ID
            modestbranding: 1, // Hide YouTube logo
            rel: 0, // Disable related videos at the end
            cc_load_policy: 1, // Enable closed captions by default
            hl: "en", // Set default language for captions (English)
          },
        })
      }
    }, 500)

    return () => clearInterval(checkYTReady) // Cleanup on component unmount
  }, [])

  // Function to play the video at a given index
  const playVideoAtIndex = (index) => {
    if (player) {
      console.log(`Playing video at index: ${index}`)
      player.playVideoAt(index)
    } else {
      console.log("Player not initialized yet.")
    }
  }

  const buttonColors = [
    { backgroundColor: "crimson", color: "white" }, // Crimson
    { backgroundColor: "scarlet", color: "white" }, // Scarlet
    { backgroundColor: "black", color: "white" }, // Black
  ]
  // Array of act labels
  const actLabels = ["Act I", "Act II", "Act III", "Act IV", "Act V", "Encore"]

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        alignItems: "center",
        padding: "2rem",
        gap: "2rem",
      }}
    >
      <Helmet>
        <title>The Masquerade of Folly</title>
      </Helmet>
      <Typography
        textAlign={"center"}
        alignItems={"center"}
        fontWeight={900}
        fontSize={34}
        sx={{ textDecoration: "underline" }}
      >
        The Masquerade of Folly
      </Typography>

      {/* Buttons to play specific videos by index */}
      <Grid container spacing={2} justifyContent="center">
        {actLabels.map((label, index) => (
          <Grid item key={index}>
            <Button
              sx={buttonColors[index % buttonColors.length]} // Cycle through Crimson, Scarlet, Black
              variant="contained"
              onClick={() => playVideoAtIndex(index)}
            >
              {label} {/* Button label as Act I, Act II, etc. */}
            </Button>
          </Grid>
        ))}
      </Grid>
      <Grid>
        <Grid
          item
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "2rem",
          }}
        >
          <div
            id="youtube-player"
            style={{
              width: isMobileScreen ? "375px" : "560px",
              height: isMobileScreen ? "280px" : "315px",
            }}
          ></div>
        </Grid>
        <Grid item>
          <Box>
            <Typography
              id="masquerade-summary-title"
              variant="h6"
              component="h2"
              fontWeight={900}
              sx={{ mb: "1rem" }}
              textAlign={"center"}
            >
              Summary of "The Masquerade of Folly: A Tragic Chronicle of
              Highland Park"
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "2rem" }}>
              {acts.map((act, index) => (
                <Box key={index}>
                  <Typography
                    fontWeight={600}
                    sx={{ textDecoration: "underline" }}
                    id="masquerade-summary-description"
                  >
                    {act.title}
                  </Typography>
                  <Typography id="masquerade-summary-description">
                    {act.description}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Masquerade
