import { Box, Typography } from "@mui/material"
import React from "react"
import Emailer from "../components/Emailer"

const GetMerch = () => {
  return (
    <Box>
      <Typography>GetMerch</Typography>
      <Emailer />
    </Box>
  )
}

export default GetMerch
